import { VariantProps, cva } from "class-variance-authority";

const spinner = cva("inline-block animate-spin", {
  variants: {
    size: {
      xs: "h-4 w-4",
      sm: "h-5 w-5",
      md: "h-6 w-6",
      lg: "h-8 w-8",
    },
    color: {
      contrast: "text-contrast",
      content: "text-content",
      primary: "text-primary",
      white: "text-white",
      muted: "text-content/60",
    },
  },
  defaultVariants: {
    size: "sm",
    color: "contrast",
  },
});

interface SpinnerProps extends VariantProps<typeof spinner> {}

export const Spinner = ({ size, color }: SpinnerProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={spinner({ size, color })}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  );
};
